var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": "Реквизиты для вывода",
      "width": 600,
      "actions": _vm.actions
    }
  }, [_vm.method && _vm.state ? _c(_vm.forms[_vm.method], {
    ref: "form",
    tag: "component",
    attrs: {
      "details": _vm.state.details[_vm.method]
    },
    on: {
      "input": function ($event) {
        _vm.details = $event;
      }
    }
  }) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };