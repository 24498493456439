var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('UiFormGridVertical', [_c('UiFormInput', {
    attrs: {
      "label": "Имя (обязательно)",
      "field": _vm.fields.personal_name
    },
    on: {
      "blur": function ($event) {
        return _vm.onBlur(_vm.fields.personal_name);
      }
    },
    model: {
      value: _vm.fields.personal_name.model,
      callback: function ($$v) {
        _vm.$set(_vm.fields.personal_name, "model", $$v);
      },
      expression: "fields.personal_name.model"
    }
  }), _c('UiFormInput', {
    attrs: {
      "label": "Телефон или имя пользователя в телеграм (обязательно)",
      "field": _vm.fields.phone_or_telegram
    },
    on: {
      "blur": function ($event) {
        return _vm.onBlur(_vm.fields.phone_or_telegram);
      }
    },
    model: {
      value: _vm.fields.phone_or_telegram.model,
      callback: function ($$v) {
        _vm.$set(_vm.fields.phone_or_telegram, "model", $$v);
      },
      expression: "fields.phone_or_telegram.model"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };