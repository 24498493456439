import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { default as FormMixin, required } from "@/components/Form/Form.mixin";
import { mapValues } from "lodash";
export default {
  mixins: [FormMixin],
  props: {
    details: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    fields: {
      deep: true,
      handler(v) {
        this.$emit("input", mapValues(v, field => field.model));
      }
    }
  },
  data() {
    return {
      fields: {
        personal_name: this.field({
          validators: [required()]
        }),
        phone_or_telegram: this.field({
          validators: [required()]
        })
      }
    };
  },
  mounted() {
    if (this.details && typeof this.details === "object") {
      Object.keys(this.details).forEach(key => {
        if (this.fields[key]) {
          this.fields[key].model = this.details[key];
        }
      });
    }
  }
};