var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('UiFormGridVertical', [_c('UiFormInput', {
    attrs: {
      "label": "Имя (обязательно)",
      "field": _vm.fields.personal_name
    },
    on: {
      "blur": function ($event) {
        return _vm.onBlur(_vm.fields.personal_name);
      }
    },
    model: {
      value: _vm.fields.personal_name.model,
      callback: function ($$v) {
        _vm.$set(_vm.fields.personal_name, "model", $$v);
      },
      expression: "fields.personal_name.model"
    }
  }), _c('UiFormInput', {
    attrs: {
      "label": "Телефон или имя пользователя в телеграм (обязательно)",
      "field": _vm.fields.phone_or_telegram
    },
    on: {
      "blur": function ($event) {
        return _vm.onBlur(_vm.fields.phone_or_telegram);
      }
    },
    model: {
      value: _vm.fields.phone_or_telegram.model,
      callback: function ($$v) {
        _vm.$set(_vm.fields.phone_or_telegram, "model", $$v);
      },
      expression: "fields.phone_or_telegram.model"
    }
  }), _c('UiFormInput', {
    attrs: {
      "label": "Название организации (обязательно)",
      "field": _vm.fields.name
    },
    on: {
      "blur": function ($event) {
        return _vm.onBlur(_vm.fields.name);
      }
    },
    model: {
      value: _vm.fields.name.model,
      callback: function ($$v) {
        _vm.$set(_vm.fields.name, "model", $$v);
      },
      expression: "fields.name.model"
    }
  }), _c('UiFormInput', {
    attrs: {
      "label": "ИНН (обязательно)",
      "field": _vm.fields.inn
    },
    on: {
      "blur": function ($event) {
        return _vm.onBlur(_vm.fields.inn);
      }
    },
    model: {
      value: _vm.fields.inn.model,
      callback: function ($$v) {
        _vm.$set(_vm.fields.inn, "model", $$v);
      },
      expression: "fields.inn.model"
    }
  }), _c('UiFormInput', {
    attrs: {
      "label": "КПП (необязательно)",
      "field": _vm.fields.kpp
    },
    on: {
      "blur": function ($event) {
        return _vm.onBlur(_vm.fields.kpp);
      }
    },
    model: {
      value: _vm.fields.kpp.model,
      callback: function ($$v) {
        _vm.$set(_vm.fields.kpp, "model", $$v);
      },
      expression: "fields.kpp.model"
    }
  }), _c('UiFormInput', {
    attrs: {
      "label": "ОГРН / ОГРНИП (обязательно)",
      "field": _vm.fields.ogrn
    },
    on: {
      "blur": function ($event) {
        return _vm.onBlur(_vm.fields.ogrn);
      }
    },
    model: {
      value: _vm.fields.ogrn.model,
      callback: function ($$v) {
        _vm.$set(_vm.fields.ogrn, "model", $$v);
      },
      expression: "fields.ogrn.model"
    }
  }), _c('UiFormInput', {
    attrs: {
      "component": "textarea",
      "label": "Юридический адрес (обязательно)",
      "field": _vm.fields.address
    },
    on: {
      "blur": function ($event) {
        return _vm.onBlur(_vm.fields.address);
      }
    },
    model: {
      value: _vm.fields.address.model,
      callback: function ($$v) {
        _vm.$set(_vm.fields.address, "model", $$v);
      },
      expression: "fields.address.model"
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };