import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
import WithdrawalDetailsBank from "./WithdrawalDetailsBank";
import WithdrawalDetailsCard from "./WithdrawalDetailsCard";
import WithdrawalDetailsCrypto from "./WithdrawalDetailsCrypto";
export default {
  mixins: [ModalMixin],
  data() {
    return {
      forms: {
        bank_invoice: WithdrawalDetailsBank,
        card: WithdrawalDetailsCard,
        crypto: WithdrawalDetailsCrypto
      },
      method: null,
      state: null,
      details: null
    };
  },
  mounted() {},
  methods: {
    async onOpen({
      method
    }) {
      this.method = method;
      this.state = await this.$store.dispatch("partner/state");
    },
    async submit({
      close
    }) {
      if (!this.method || !this.state || !(await this.$refs.form.validate(true))) {
        return;
      }
      const withdrawal = await this.$store.dispatch("partner/postWithdrawal", {
        method: this.method,
        details: this.details
      });
      await this.$modal.process(() => import("@/components/Partner/ModalWithdrawalSuccess"), {
        withdrawal
      }).catch(() => Promise.resolve());
      this.$emit("complete", withdrawal);
      close();
    }
  },
  computed: {
    actions() {
      return ["cancel", {
        name: "submit",
        text: "Оформить заявку",
        action: ({
          close
        }) => {
          this.submit({
            close
          });
        }
        // loading: this.loading,
      }];
    }
  },
  components: {
    Modal
  }
};