/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./WithdrawalDetailsCrypto.vue?vue&type=template&id=560ed1c2&scoped=true"
import script from "./WithdrawalDetailsCrypto.vue?vue&type=script&lang=js"
export * from "./WithdrawalDetailsCrypto.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "560ed1c2",
  null
  
)

export default component.exports